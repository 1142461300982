<!--Prop Section => 1 = Settings List, 2 = Project Detail, 3 = User Detail-->
<template>
  <div>
    <!--Title Bar-->
    <title-bar
      v-if="section === 1"
      :title-value="'Projects'"
    />

    <!-- Filter -->
    <v-toolbar
      flat
      dense
      color="accent"
    >
      <!--  Filter Icon -->
      <v-menu
        v-model="filterMenu"
        offset-y
        right
        :close-on-content-click="false"
      >
        <template #activator="{ on: menu }">
          <v-tooltip
            right
            color="primary"
          >
            <template #activator="{ on: tooltip }">
              <v-btn
                icon
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon
                  v-if="showFiltered()"
                  color="primary"
                >
                  fas fa-filter
                </v-icon>
                <v-icon
                  v-else
                  color="primary"
                >
                  fal fa-filter
                </v-icon>
              </v-btn>
            </template>
            <span>Filter</span>
          </v-tooltip>
        </template>

        <v-card width="400px">
          <v-card-title>
            <v-spacer />
            <v-btn
              v-if="showFiltered()"
              text
              rounded
              outlined
              block
              @click="clearFilter()"
            >
              <v-icon
                small
                left
              >
                fal fa-times
              </v-icon>Clear Filters
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid>
              <v-row dense>
                <!--Cohort ID-->
                <v-col
                  v-if="section === 1"
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="id_cohort"
                    :items="cohortsStore.cohortsValueList"
                    label="Cohort"
                    placeholder=" "
                    item-text="name"
                    item-value="id"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Code-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="code"
                    label="Code"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Title-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="title"
                    label="Title"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Managing PO-->
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-text-field
                    v-model="managing_po"
                    label="Managing PO"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--NCE Flag-->
                <v-col
                  v-if="section === 1"
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="flag_nce"
                    :items="valueListsStore.yesNo"
                    label="No Cost Extension period (NCE)"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col
                  v-if="section === 1"
                  cols="12"
                  sm="12"
                >
                  <v-autocomplete
                    v-model="flag_status"
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    item-value="id"
                    item-text="value"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="mx-4 pb-4">
            <v-btn
              block
              rounded
              color="primary"
              @click="filter()"
            >
              <v-icon
                small
                left
              >
                fal fa-check
              </v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <!--Pagination  -->
      <pagination
        :show="projectsStore.projects.data && projectsStore.projects.data.length > 0 && projectsStore.projects.last_page > 1 ? true : false"
        :current-page="projectsStore.projects.current_page"
        :last-page="projectsStore.projects.last_page"
        @paginate-event="filter($event)"
      />

      <v-spacer />

      <!--Entry Dialog-->
      <project :section="section" />
    </v-toolbar>
    
    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-basic
        :show="projectsStore.projects.data && projectsStore.projects.data.length > 0 ? true : false"
        :list-data="projectsStore.projects.data"
        :title="'Projects'"
        :section="section === 1 ? 1 : 1.2"
        :button-title="'Project'"
        :actions="actions"
        @open-entry="openEntry($event)"
        @open-detail="openDetail($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import TitleBar from '@/components/TitleBar';
import Project from '@/components/Project';
import Pagination from '@/components/Pagination';
import ListBasic from '@/components/ListBasic';

const { mapFields } = createHelpers({
  getterType: 'projectsStore/getFilterField',
  mutationType: 'projectsStore/updateFilterField',
});

export default {
  name: 'Projects',
  components: {
    TitleBar,
    Project,
    Pagination,
    ListBasic
  },
   props: {
     /*1 = Project Settings, 2 = Project Detail, 3 = User Detail*/
    section: Number,
  },
  data() {
    return {
      filterMenu: false,
      actions: {
        menu: this.section === 1 ? 1 : 0,
        view: this.section === 1 ? 1 : 0,
        edit: this.section === 1 ? 1 : 2,
        password:0,
        invite:0,
        email:0
      },
    };
  },
  computed: {
    ...mapState({
      usersStore: state => state.usersStore,
      progressStore: state => state.progressStore,
      projectsStore: state => state.projectsStore,
      cohortsStore: state => state.cohortsStore,
      valueListsStore: state => state.valueListsStore,
    }),
    ...mapFields([
      'page',
      'sectionStored',

      'id_user',
      'id_cohort',
      'code',
      'title',
      'managing_po',
      'flag_nce',
      'flag_status',
    ]),
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {

      this.$store.dispatch('progressStore/set',true)

      this.changeSectionCheck();

      if(this.section === 3) {

        let user = this.$store.getters['usersStore/getUser'];
        this.id_user = user.id
      }
      else {
        this.id_user = ''
      }

      /* Pull Cohorts For Value List */
      await this.$store.dispatch('cohortsStore/valueList')
      /* Pull Jurisdictions For Value List */
      await this.$store.dispatch('jurisdictionsStore/valueList')

      this.sectionStored = this.section;

	    /* Grab filters */
      let filters = this.$store.getters['projectsStore/getFilters'];
      
      /* Grab projects based on active filters */
      await this.$store.dispatch('projectsStore/fetch', filters);
      this.$store.dispatch('progressStore/set',false)
    },

	  async filter(page) {
      this.filterMenu = false;
      this.page = page;
		  this.initialize();
    },
    
    clearFilterColumns() {
		  this.id_cohort = '';
      this.code = '';
      this.title = '';
      this.managing_po = '';
      this.flag_nce = '';
      this.flag_status = '';
      this.page = 1;
    },

	  async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
		  this.initialize();
    },
    
    changeSectionCheck() {
      if(this.sectionStored !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch('projectsStore/clearProjects');
      }
    },

	  async openEntry(id) {
      /* Pull Cohorts For Value List */
      await this.$store.dispatch('cohortsStore/valueList')
      await this.$store.dispatch('projectsStore/entry', id);
      let project = this.$store.getters['projectsStore/getEntry'];
      this.jurisdictionsByCohort(project.id_cohort);
    },

    async openDetail(id) {
      this.$store.dispatch('progressStore/set',true);

      await this.$store.dispatch('projectsStore/read', id);

      this.$router.push({name: 'projectDetail', params: { id: id } });
    },
    
    showFiltered(){
      if(this.code || this.managing_po || this.id_cohort || this.title || (this.flag_nce === 0 || this.flag_nce === 1)|| (this.flag_status === 0 || this.flag_status === 1)) {
        return true
      }
    },

    async jurisdictionsByCohort(cohortID) {
      this.jurisdictions=[]
      let data = {
        id_cohort: cohortID
      }
      await this.$store.dispatch('jurisdictionsStore/byCohort', data)
    }

  },
};
</script>

<style scoped>
  .clickable{
    cursor:pointer;
  }
  button {
    outline: none;
  }
</style>
